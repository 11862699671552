import { DateFormat } from "@ui/interfaces";
import { LastDayFormat } from "@ui/utils";

interface FormatterList {
    lang: string;
    formatter: DateFormat;
}

const formatters: FormatterList[] = [
    {
        lang: "en-US",
        formatter: new LastDayFormat()
    }
];

export class LastDayFormatStrategy implements DateFormat {
    handle(date: string): string {
        const chosenFormatter = formatters.find(
            ({ lang }) => lang === localStorage.getItem("I18N_LANGUAGE")
        );

        if (!chosenFormatter) {
            return new LastDayFormat().handle(date);
        }

        return chosenFormatter?.formatter.handle(date);
    }

    static format(value: string): string {
        return new LastDayFormatStrategy().handle(value);
    }
}
