import { Source, Provider, GroupedPlanning } from "@domain/entities";
import { BillsEvents } from "@domain/events/BillsEvents";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";
import { GetBankAccountService } from "@domain/services/account/GetBankAccountService";
import { EditEffectiveBillsService, GetNonEffectiveBillsService } from "@domain/services/bills";
import { GetGroupedPlanningService } from "@domain/services/planning";
import { GetProviderService } from "@domain/services/provider";
import { GetSourcesService } from "@domain/services/sources";
import { FormValidation } from "@presentation/validation/interfaces";
import { EditEffectiveBillsViewContract } from "@presentation/views/bills";
import { EditEffectiveBillsPresenterContract } from "@ui/presenters/bills";
import { EventEmitter } from "@utils/events";

export class EditEffectiveBillsPresenter implements EditEffectiveBillsPresenterContract {
    private view!: EditEffectiveBillsViewContract;

    constructor(
        private readonly validation: FormValidation,
        private readonly editEffectiveBillsService: EditEffectiveBillsService,
        private readonly getAccountsService: GetBankAccountService,
        private readonly getSourcesService: GetSourcesService,
        private readonly getProviderService: GetProviderService,
        private readonly getPlanningService: GetGroupedPlanningService,
        private readonly getNonEffectiveBillsService: GetNonEffectiveBillsService
    ) { }

    setView(view: EditEffectiveBillsViewContract): void {
        this.view = view;
    }

    async edit(payload: EditEffectiveBillsPresenterContract.Payload): Promise<boolean> {
        this.view.setLoading(true);

        const validationSuccessfull = this.validation.allValid(payload);

        if (!validationSuccessfull) {
            this.view.updateValidationState(this.validation.errors(payload));
            this.view.setLoading(false);
            return false;
        }

        const isEffectiveBillsEdited = await this.editEffectiveBillsService.handle(payload);

        if (isEffectiveBillsEdited) {
            EventEmitter.emit(BillsEvents.BILLS_UPDATED);
        }

        this.view.updateValidationState([]);
        this.view.setLoading(false);

        return isEffectiveBillsEdited;
    }

    async getAccounts(): Promise<GroupsByFlowsList[]> {
        const accounts = await this.getAccountsService.handle();
        this.view.setAccounts(accounts);
        return accounts;
    }

    async getSources(): Promise<Source[]> {
        const sources = await this.getSourcesService.handle();
        this.view.setSources(sources);
        return sources;
    }

    async getProvider(): Promise<Provider[]> {
        const providers = await this.getProviderService.handle();
        this.view.setProviders(providers);
        return providers;
    }

    async getPlanning(): Promise<GroupedPlanning[]> {
        const planning = await this.getPlanningService.handle();
        this.view.setPlanning(planning);
        return planning;
    }

    async getNonEffective(params: GetNonEffectiveBillsService.Params): Promise<GetNonEffectiveBillsService.Response[]> {
        const nonEffective = await this.getNonEffectiveBillsService.handle(params);
        this.view.setNonEffective(nonEffective);
        return nonEffective;
    }
}
