import { HttpUploadPlanningBillsService } from "@data/services/bills";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { UploadPlanningBillsPresenter } from "@presentation/presenters/bills/UploadPlanningBillsPresenter";
import { UploadPlanningBillsPresenterContract } from "@ui/presenters/bills";

export const makeUploadPlanningBillsPresenter = (): UploadPlanningBillsPresenterContract => {
    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const uploadPlanningBillsService = new HttpUploadPlanningBillsService(httpRepository);
    return new UploadPlanningBillsPresenter(
        uploadPlanningBillsService
    );
};
