import React, { useMemo } from "react";

import UploadPlanningBillsView from "@ui/views/bills/UploadPlanningBillsView";
import { makeUploadPlanningBillsPresenter } from "@main/factories/presenters/bills";

interface UploadPlanningBillsProps {
    isOpen: boolean;
    onDismiss(): void;
 }

const UploadPlanningBills: React.FC<UploadPlanningBillsProps> = (props) => {
    const uploadPlanningBillsPresenter = useMemo(() => makeUploadPlanningBillsPresenter(), []);

    return <UploadPlanningBillsView { ...props } uploadPlanningBillsPresenter={uploadPlanningBillsPresenter} />;
};

export default UploadPlanningBills;
