import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { GetNonEffectiveBillsService } from "@domain/services/bills";

export class HttpGetNonEffectiveBillsService implements GetNonEffectiveBillsService {
    constructor(
        private readonly httpRepository: HttpRepository
    ) { }

    async handle(params: GetNonEffectiveBillsService.Params): Promise<GetNonEffectiveBillsService.Response[]> {
        const request = HttpRequestBuilder
            .requestToUrl('/bills/noneffective')
            .withMethod(HttpRequestTypes.GET)
            .withParams({
                start: params.start,
                end: params.end,
                transactionType: params.transactionType,
                transactionSubgroupId: params.transactionSubgroupId
            })
            .build();

        const { data } = await this.httpRepository.makeRequest<any, GetNonEffectiveBillsService.Response[]>(request);

        return data;
    }
}
