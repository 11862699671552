import React from "react";
import { FaUpload } from "react-icons/fa";

interface UploadActionButtonProps {
   isLastOfList?: boolean;
}

const UploadActionButton: React.FC<
   UploadActionButtonProps &
   React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ isLastOfList, ...props }) => {
   return (
      <button
         type="button"
         className={`btn btn-primary mb-3 ms-1 ${isLastOfList ? "" : "me-1"}`}
         {...props}
      >
         <FaUpload/>
      </button>
   );
};

export default UploadActionButton;
