import { DateFormat } from "@ui/interfaces";
import { FirstDayFormat } from "@ui/utils";

interface FormatterList {
   lang: string;
   formatter: DateFormat;
}


const formatters: FormatterList[] = [
   {
    lang: "en-US",
    formatter: new FirstDayFormat()
   }
];

export class FirstDayFormatStrategy implements DateFormat {
   handle(date: string): string {
      const chosenFormatter = formatters.find(
         ({ lang }) => lang === localStorage.getItem("I18N_LANGUAGE")
      );

      if (!chosenFormatter) {
         return new FirstDayFormat().handle(date);
      }

      return chosenFormatter?.formatter.handle(date);
   }

   static format(value: string): string {
      return new FirstDayFormatStrategy().handle(value);
   }
}
