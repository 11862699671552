import { parseISO, endOfMonth, format } from "date-fns";
import { DateFormat } from "@ui/interfaces";

export class LastDayFormat implements DateFormat {
    handle(date: string): string {
        const newDate = format(endOfMonth(parseISO(date)), "yyyy-MM-dd");

        return newDate;
    }
}
