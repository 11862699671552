import { UploadPlanningBillsService } from "@domain/services/bills";
import { UploadPlanningBillsViewContract } from "@presentation/views/bills";
import { UploadPlanningBillsPresenterContract } from "@ui/presenters/bills";

export class UploadPlanningBillsPresenter implements UploadPlanningBillsPresenterContract {
    private view!: UploadPlanningBillsViewContract;

    constructor (
        private readonly uploadPlanningBillsService: UploadPlanningBillsService
    ) { }

    async upload(params: UploadPlanningBillsPresenterContract.Params): Promise<UploadPlanningBillsPresenterContract.Response> {
        this.view.setLoading(true);

        const bills = await this.uploadPlanningBillsService.handle(params);

        this.view.setParams(params);
        this.view.setLoading(false);
        return bills;
    }

    setView(view: UploadPlanningBillsViewContract): void {
            this.view = view;
        }
}
