import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { UploadPlanningBillsService } from "@domain/services/bills";

export class HttpUploadPlanningBillsService implements UploadPlanningBillsService {
    constructor(
        private readonly httpRepository: HttpRepository
    ) { }

    async handle(params: UploadPlanningBillsService.Params): Promise<UploadPlanningBillsService.Response> {
        const request = HttpRequestBuilder
            .requestToUrl('/bills/upload')
            .withMethod(HttpRequestTypes.GET)
            .withParams({
                file: params.file
            })
            .build();

        const { data } = await this.httpRepository.makeRequest<any, UploadPlanningBillsService.Response>(request);

        return data;
    }
}
