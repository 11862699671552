import { GroupedPlanning, Provider, Source } from "@domain/entities";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";
import { GetNonEffectiveBillsService } from "@domain/services/bills";
import { useValidation } from "@ui/hooks";
import { SelectItem } from "@ui/interfaces";
import { EditEffectiveBillsPresenterContract } from "@ui/presenters/bills";
import { DateFormatStrategy, MoneyFormatStrategy } from "@ui/strategy";
import { FirstDayFormatStrategy } from "@ui/strategy/FirstDayFormatStrategy";
import { LastDayFormatStrategy } from "@ui/strategy/LastDayFormatStrategy";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useParams } from "react-router-dom";
import { Col, FormGroup, Label, Modal, Row } from "reactstrap";

interface EditEffectiveBillsViewProps {
    t: any;
    onDismiss(): void;
    billsToEdit: EditEffectiveBillsPresenterContract.Payload | null;
    editEffectiveBillsPresenter: EditEffectiveBillsPresenterContract;
}

interface FormPayload {
    id: number;
    cashflow: string;
    transactionType: string;
    amount: number;
    date: string;
    description: string;
    bankAccountId: number;
    sourceId: number;
    providerId: number;
    transactionGroupId: number;
    transactionSubgroupId: number;
    nonEffectiveBillsId: number;
}

const INITIAL_FORM_STATE: FormPayload = {
    id: 0,
    cashflow: "",
    transactionType: "",
    amount: 0,
    date: "",
    description: "",
    bankAccountId: 0,
    sourceId: 0,
    providerId: 0,
    transactionGroupId: 0,
    transactionSubgroupId: 0,
    nonEffectiveBillsId: 0
};

const EditEffectiveBillsView: React.FC<EditEffectiveBillsViewProps> = ({ t, onDismiss, billsToEdit, editEffectiveBillsPresenter }) => {
    const { type } = useParams<{ type: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [planning, setPlanningList] = useState<GroupedPlanning[]>([]);
    const [bankAccount, setBankAccountList] = useState<GroupsByFlowsList[]>([]);
    const [source, setSourceList] = useState<Source[]>([]);
    const [provider, setProviderList] = useState<Provider[]>([]);
    const [payload, setPayload] = useState<FormPayload>(INITIAL_FORM_STATE);
    const [nonEffective, setNonEffective] = useState<GetNonEffectiveBillsService.Response[]>([]);
    const [currentStep, setCurrentStep] = useState(1);

    const goToNextStep = () => setCurrentStep((prev) => prev + 1);

    const { ValidationFeedback, updateValidationState, validationState, ValidationSelect, ValidationInput } = useValidation();

    useEffect(() => {
        editEffectiveBillsPresenter.setView({
            setAccounts,
            setPlanning,
            setLoading,
            setProviders,
            setSources,
            setNonEffective,
            updateValidationState
        });

        editEffectiveBillsPresenter.getAccounts();
        editEffectiveBillsPresenter.getProvider();
        editEffectiveBillsPresenter.getSources();
        editEffectiveBillsPresenter.getPlanning();
    }, []);

    useEffect(() => {
        if (!billsToEdit) {
            updateValidationState([]);
            setPayload(INITIAL_FORM_STATE);
        } else {
            setPayload(billsToEdit);
        }
    }, [billsToEdit]);

    useEffect(() => {
        if (currentStep === 2) {

            editEffectiveBillsPresenter.getNonEffective({
                start: FirstDayFormatStrategy.format(payload.date),
                end: LastDayFormatStrategy.format(payload.date),
                transactionSubgroupId: payload.transactionSubgroupId,
                transactionType: type
            });
        }
    }, [currentStep]);

    const setLoading = (isLoading: boolean) => setIsLoading(isLoading);
    const setAccounts = (account: GroupsByFlowsList[]) => setBankAccountList(account);
    const setSources = (source: Source[]) => setSourceList(source);
    const setProviders = (provider: Provider[]) => setProviderList(provider);
    const setPlanning = (planning: GroupedPlanning[]) => setPlanningList(planning);

    const updateField = (fieldName: string, value: string) => {
        setPayload((oldState) => ({
            ...oldState,
            [fieldName]: value,
        }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (currentStep === 1) {
            goToNextStep();
            return;
        }

        editEffectiveBillsPresenter.edit({
            amount: payload.amount,
            bankAccountId: Number(payload.bankAccountId),
            cashflow: payload.cashflow,
            date: String(payload.date),
            description: payload.description,
            id: Number(payload.id),
            nonEffectiveBillsId: Number(payload.nonEffectiveBillsId),
            providerId: Number(payload.providerId),
            sourceId: Number(payload.sourceId),
            transactionGroupId: Number(payload.transactionGroupId),
            transactionSubgroupId: Number(payload.transactionSubgroupId),
            transactionType: type
        });
    };

    const group = bankAccount.find(({ id }) => id === payload.cashflow)?.groups || [];
    const accounts = group.find(({ id }) => id === "free" || "linked")?.accounts || [];
    const plannings = planning.find(({ id }) => id === payload.cashflow)?.transactionTypes || [];
    const groups = plannings.find(({ id }) => id == type)?.groups || [];
    const subgroups = groups.find(({ id }) => id == payload.transactionGroupId)?.subgroups || [];

    const optionsCashflow: SelectItem[] = planning.map(({ id, name }) => ({ label: name, value: id }));
    const optionsSource: SelectItem[] = source.map(({ id, name }) => ({ label: name, value: id }));
    const optionsProvider: SelectItem[] = provider.map(({ id, name }) => ({ label: name, value: id }));
    const optionsAccount: SelectItem[] = accounts.map(({ id, name }) => ({ label: name, value: id }));
    const optionBillId: SelectItem[] = nonEffective.map(
        ({ id, date, amount }) =>
        ({
            label: `${t("Expected date")}: ${DateFormatStrategy.format(date)} 
            - ${t("Expected amount")}: ${MoneyFormatStrategy.format(amount)}`, value: id
        })
    );

    const optionsGroups: SelectItem[] = groups.map(
        ({ id, name }) => ({ label: name, value: id })
    );
    const optionsSubgroup: SelectItem[] = subgroups.map(
        ({ id, name }) => ({ label: name, value: id })
    );

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div className="modal-body">
                        <Row>
                            <Col md={6} className="mb-2">
                                <FormGroup>
                                    <Label>{t("Cashflow")}</Label>
                                    <ValidationSelect
                                        field="cashflow"
                                        validationState={validationState}
                                        name="cashflow"
                                        className="form-control"
                                        placeholder={`${t("Select")}`}
                                        value={payload.cashflow}
                                        options={optionsCashflow}
                                        onChange={({ target: { value, name } }) =>
                                            updateField(name, value)
                                        }
                                    />

                                    <ValidationFeedback field="cashflow" />
                                </FormGroup>
                            </Col>

                            <Col md={6} className="mb-2">
                                <FormGroup>
                                    <Label>{t("Source")}</Label>
                                    <ValidationSelect
                                        field="sourceId"
                                        validationState={validationState}
                                        name="sourceId"
                                        className="form-control"
                                        placeholder={`${t("Select")}`}
                                        value={payload.sourceId}
                                        options={optionsSource}
                                        onChange={({ target: { value, name } }) =>
                                            updateField(name, value)
                                        }
                                    />

                                    <ValidationFeedback field="sourceId" />
                                </FormGroup>
                            </Col>

                            <Col md={6} className="mb-2">
                                <FormGroup>
                                    <Label>{t("Provider")}</Label>
                                    <ValidationSelect
                                        field="providerId"
                                        validationState={validationState}
                                        name="providerId"
                                        className="form-control"
                                        placeholder={`${t("Select")}`}
                                        value={payload.providerId}
                                        options={optionsProvider}
                                        onChange={({ target: { value, name } }) =>
                                            updateField(name, value)
                                        }
                                    />

                                    <ValidationFeedback field="providerId" />
                                </FormGroup>
                            </Col>

                            <Col md={6} className="mb-2">
                                <FormGroup>
                                    <Label>{t("Account")}</Label>
                                    <ValidationSelect
                                        field="bankAccountId"
                                        validationState={validationState}
                                        name="bankAccountId"
                                        className="form-control"
                                        placeholder={`${t("Select")}`}
                                        value={payload.bankAccountId}
                                        options={optionsAccount}
                                        onChange={({ target: { value, name } }) =>
                                            updateField(name, value)
                                        }
                                    />

                                    <ValidationFeedback field="bankAccountId" />
                                </FormGroup>
                            </Col>

                            <Col md={6} className="mb-2">
                                <FormGroup>
                                    <Label>{t("Groups")}</Label>
                                    <ValidationSelect
                                        field="transactionGroupId"
                                        validationState={validationState}
                                        name="transactionGroupId"
                                        className="form-control"
                                        placeholder={`${t("Select")}`}
                                        value={payload.transactionGroupId}
                                        options={optionsGroups}
                                        onChange={({ target: { value, name } }) =>
                                            updateField(name, value)
                                        }
                                    />

                                    <ValidationFeedback field="transactionGroupId" />
                                </FormGroup>
                            </Col>

                            <Col md={6} className="mb-2">
                                <FormGroup>
                                    <Label>{t("Subgroup")}</Label>
                                    <ValidationSelect
                                        field="transactionSubgroupId"
                                        validationState={validationState}
                                        name="transactionSubgroupId"
                                        className="form-control"
                                        placeholder={`${t("Select")}`}
                                        value={payload.transactionSubgroupId}
                                        options={optionsSubgroup}
                                        onChange={({ target: { value, name } }) =>
                                            updateField(name, value)
                                        }
                                    />

                                    <ValidationFeedback field="transactionSubgroupId" />
                                </FormGroup>
                            </Col>

                            <Col md={6} className="mb-2">
                                <Label>{t("Balance")}</Label>
                                <FormGroup>
                                    <NumericFormat
                                        field="amount"
                                        validationState={validationState}
                                        name="amount"
                                        className="form-control"
                                        placeholder={t("Balance")}
                                        thousandSeparator="."
                                        customInput={ValidationInput}
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        onValueChange={({ floatValue }) => {
                                            setPayload({
                                                ...payload,
                                                amount: floatValue ?? 0,
                                            });
                                        }}
                                    />

                                    <ValidationFeedback field="amount" />
                                </FormGroup>
                            </Col>

                            <Col md={6} className="mb-2">
                                <Label
                                    htmlFor="example-date-input"
                                    className="form-Label">
                                    {t("Date")}
                                </Label>

                                <ValidationInput
                                    field="date"
                                    validationState={validationState}
                                    className="form-control"
                                    type="date"
                                    id="example-date-input"
                                    onChange={({ target: { value } }) => setPayload({ ...payload, date: value })}
                                />

                                <ValidationFeedback field="date" />
                            </Col>

                            <Col md={12} className="mb-2">
                                <FormGroup>
                                    <Label>{t("Description")}</Label>
                                    <ValidationInput
                                        field="description"
                                        validationState={validationState}
                                        name="description"
                                        type="textarea"
                                        className="form-control"
                                        placeholder={`${t("Digit Here")}`}
                                        value={payload.description}
                                        onChange={({ target: { value, name } }) =>
                                            updateField(name, value)
                                        }
                                    />

                                    <ValidationFeedback field="description" />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                );
            case 2:
                return (
                    <div className="modal-body">
                        <Row>
                            <Col md={12} className="mb-2">
                                <Label>
                                    {t("bills.forecast")}
                                </Label>

                                <ValidationSelect
                                    field="nonEffectiveBillsId"
                                    validationState={validationState}
                                    name="nonEffectiveBillsId"
                                    className="form-control"
                                    placeholder={`${t("Select")}`}
                                    value={payload.nonEffectiveBillsId}
                                    options={optionBillId}
                                    onChange={({ target: { value, name } }) =>
                                        updateField(name, value)
                                    }
                                />

                                <ValidationFeedback field="nonEffectiveBillsId" />
                            </Col>
                        </Row>
                    </div>
                );
        }
    };

    const buttonText = t(isLoading ? "loading" : currentStep === 1 ? "next" : "Save");

    return (
        <Modal
            isOpen={Boolean(billsToEdit)}
            toggle={isLoading ? undefined : onDismiss}
            scrollable={true}
            id="staticBackdrop"
            size="lg"
        >
            <div className="modal-header">
                <h5 className="modal-title">{t("bills.edit")}</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={isLoading ? undefined : onDismiss}
                    aria-label="Close"
                />
            </div>

            <form onSubmit={handleSubmit}>
                <div className="modal-body">{renderStepContent()}</div>

                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-danger me-2"
                        onClick={isLoading ? undefined : onDismiss}
                        disabled={isLoading}
                    >
                        {t("Close")}
                    </button>

                    <button className="btn btn-primary" disabled={isLoading}>
                        {t(buttonText)}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default withTranslation()(EditEffectiveBillsView);
