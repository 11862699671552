import { parseISO, startOfMonth, format } from "date-fns";
import { DateFormat } from "@ui/interfaces";

export class FirstDayFormat implements DateFormat {
    handle(date: string): string {
        const newDate = format(startOfMonth(parseISO(date)), "yyyy-MM-dd");

        return newDate;
    }
}
