import React, { useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Table } from "reactstrap";

import {
  CashFlow,
  Group,
  PlanningItem,
  Prefecture,
  Subgroup,
  TransactionType,
} from "@domain/entities";

import { Breadcrumbs } from "@ui/components/Common";
import { PlanningPresenterContract } from "@ui/presenters/planning";
import Loading from "@ui/components/Loading";
import TableActionButton from "@ui/components/TableActionButton";
import { Unique } from "@utils/Unique";
import { EventEmitter } from "@utils/events";
import { PlanningEvents } from "@domain/events";
import CopyPlanning from "@main/pages/planning/CopyPlanning";
import { IncreaseGroup, IncreaseSubgroup } from "@ui/components/planning";
import {
  IncreaseGroupParams,
  IncreaseGroupSubmitData,
} from "@ui/components/planning/IncreaseGroup";
import {
  IncreaseSubgroupParams,
  IncreaseSubgroupSubmitData,
} from "@ui/components/planning/IncreaseSubgroup";
import { isNumber } from "lodash";
import { MountPlanningService } from "@domain/services/planning";

export interface PlanningViewProps {
  t: any;
  planningPresenter: PlanningPresenterContract;
}

interface SubgroupLevel {
  subgroupId: number;
  subgroupName: string;
}

interface GroupLevel {
  subgroups?: SubgroupLevel[];
  groupId: number;
  groupName: string;
}

interface TransactionTypeLevel {
  transactionType: string;
  transactionTypeName: string;
  groups: GroupLevel[];
}

interface CashflowLevel {
  cashflow: string;
  cashflowName: string;
  transactionTypes: TransactionTypeLevel[];
}

const PlanningView: React.FC<PlanningViewProps> = ({
  planningPresenter,
  t,
}) => {
  const [activeCashflowId, setActiveCashflowId] = useState<string>("");
  const [planning, setPlanning] = useState<CashflowLevel[]>([]);
  const [prevPlanning, setPrevPlanning] = useState<PlanningItem[]>([]);
  const [groupsList, setGroupsList] = useState<Group[]>([]);
  const [subgroupsList, setSubroupsList] = useState<Subgroup[]>([]);
  const [cashflowList, setCashflowList] = useState<CashFlow[]>([]);
  const [isCopyingPlanning, setIsCopyingPlanning] = useState<boolean>(false);

  const [increaseGroupParams, setIncreaseGroupParams] =
    useState<IncreaseGroupParams | null>(null);

  const [increaseSubgroupParams, setIncreaseSubgroupParams] =
    useState<IncreaseSubgroupParams | null>(null);

  const [transactionTypeList, setTransactionTypeList] = useState<
    TransactionType[]
  >([]);

  const [selectedPrefecture, setSelectedPrefecture] = useState<number>(0);
  const [prefectures, setPrefecturesList] = useState<Prefecture[]>([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    planningPresenter.setView({
      setLoadingState,
      setPrefecturePlanning,
      setPrefectures,
      setGroups,
      setSubgroups,
      setTransactionTypes,
      setCashflows,
    });

    planningPresenter.getPrefectures();
    planningPresenter.getCashflows();
    planningPresenter.getTransactionTypes();
    planningPresenter.getGroups();
    planningPresenter.getSubgroups();

    EventEmitter.subscribe(PlanningEvents.PLANNING_COPIED, onPlanningCopied);

    return () => {
      EventEmitter.subscribe(PlanningEvents.PLANNING_COPIED, onPlanningCopied);
    };
  }, []);

  useEffect(() => {
    if (planning.length && !activeCashflowId) {
      setActiveCashflowId(planning[0].cashflow);
    }
  }, [planning]);

  const onPlanningCopied = () => {
    setIsCopyingPlanning(false);
  };

  const mountBasePlanning = (): CashflowLevel[] => {
    const basePlanning: CashflowLevel[] = [];

    cashflowList.forEach((cashflow) => {
      basePlanning.push({
        cashflow: cashflow.id,
        cashflowName: cashflow.name,
        transactionTypes: transactionTypeList.map((transactionType) => ({
          transactionType: transactionType.id,
          transactionTypeName: transactionType.name,
          groups: [],
        })),
      });
    });

    return basePlanning;
  };

  useEffect(() => {
    if (selectedPrefecture) {
      if (prevPlanning.length) {
        const planningTmp: CashflowLevel[] = mountBasePlanning();

        prevPlanning.forEach((prev) => {
          planningTmp.forEach((tmp) => {
            if (tmp.cashflow === prev.cashflow.id) {
              tmp.transactionTypes.forEach((transactionTypeTmp) => {
                if (
                  transactionTypeTmp.transactionType === prev.transactionType.id
                ) {
                  const groupsNewLength = transactionTypeTmp.groups.push({
                    groupId: prev.transactionGroup.id,
                    groupName: prev.transactionGroup.name,
                  });

                  transactionTypeTmp.groups[groupsNewLength - 1].subgroups = [
                    ...(transactionTypeTmp.groups[groupsNewLength - 1]
                      .subgroups || []),
                    {
                      subgroupId: prev.transactionSubgroup.id,
                      subgroupName: prev.transactionSubgroup.name,
                    },
                  ];
                }
              });
            }
          });
        });

        setPlanning(planningTmp);
      } else {
        const planningTmp: CashflowLevel[] = mountBasePlanning();

        setPlanning(planningTmp);
      }
    } else {
      setPlanning([]);
    }
  }, [prevPlanning, selectedPrefecture]);

  const setLoadingState = (isLoading: boolean) => setLoading(isLoading);

  const setPrefecturePlanning = (planning: PlanningItem[]) =>
    setPrevPlanning(planning);

  const setPrefectures = (prefectures: Prefecture[]) =>
    setPrefecturesList(prefectures);

  const setCashflows = (cashflows: CashFlow[]) => setCashflowList(cashflows);

  const setGroups = (groups: Group[]) => setGroupsList(groups);

  const setSubgroups = (subgroups: Subgroup[]) => setSubroupsList(subgroups);

  const setTransactionTypes = (transactionTypes: TransactionType[]) =>
    setTransactionTypeList(transactionTypes);

  const handlePrefectureChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;

    setSelectedPrefecture(value ? Number(value) : 0);

    if (value) planningPresenter.getPrefecturePlanning(Number(value));
  };

  const increaseSubgroup = (data: IncreaseSubgroupSubmitData) => {
    const planningTmp = [...planning];

    planningTmp[data.cashflowLevelKey].transactionTypes[
      data.transactionTypeLevelKey
    ].groups[data.groupLevelKey].subgroups?.push({
      subgroupId: data.subgroupId,
      subgroupName:
        subgroupsList.find(({ id }) => id === data.subgroupId)?.name ?? "",
    });

    setPlanning(planningTmp);
    setIncreaseSubgroupParams(null);
  };

  const decreaseSubgroup = (data: IncreaseSubgroupSubmitData) => {
    const planningTmp = [...planning];

    const subgroupLevelKey = planningTmp[
      data.cashflowLevelKey
    ].transactionTypes[data.transactionTypeLevelKey].groups[
      data.groupLevelKey
    ].subgroups?.findIndex(({ subgroupId }) => subgroupId === data.subgroupId);

    if (isNumber(subgroupLevelKey) && subgroupLevelKey > -1) {
      planningTmp[data.cashflowLevelKey].transactionTypes[
        data.transactionTypeLevelKey
      ].groups[data.groupLevelKey].subgroups?.splice(subgroupLevelKey, 1);

      setPlanning(planningTmp);
    }
  };

  const increaseGroup = (data: IncreaseGroupSubmitData) => {
    const planningTmp = [...planning];

    planningTmp[data.cashflowLevelKey].transactionTypes[
      data.transactionTypeLevelKey
    ].groups.push({
      groupId: data.groupId,
      groupName: groupsList.find(({ id }) => id === data.groupId)?.name || "",
      subgroups: [],
    });

    setPlanning(planningTmp);
    setIncreaseGroupParams(null);
  };

  const decreaseGroup = (data: IncreaseGroupSubmitData) => {
    const planningTmp = [...planning];

    const groupLevelKey = planningTmp[data.cashflowLevelKey].transactionTypes[
      data.transactionTypeLevelKey
    ].groups.findIndex(({ groupId }) => groupId === data.groupId);

    if (isNumber(groupLevelKey) && groupLevelKey > -1) {
      planningTmp[data.cashflowLevelKey].transactionTypes[
        data.transactionTypeLevelKey
      ].groups.splice(groupLevelKey, 1);

      setPlanning(planningTmp);
    }
  };

  const savePlanning = () => {
    const planningItems: MountPlanningService.SubgroupWithGroup[] = [];

    planning.forEach((cashflowLevel) => {
      const cashflow = cashflowLevel.cashflow;

      cashflowLevel.transactionTypes.forEach((transactionTypeLevel) => {
        const transactionType = transactionTypeLevel.transactionType;

        transactionTypeLevel.groups.forEach((groupLevel) => {
          const groupId = groupLevel.groupId;

          if (groupLevel.subgroups && isNumber(groupLevel.subgroups?.length)) {
            groupLevel.subgroups.forEach((subgroupLevel) => {
              const subgroupId = subgroupLevel.subgroupId;
              planningItems.push({
                cashflow,
                groupId,
                subgroupId,
                transactionType,
              });
            });
          }
        });
      });
    });

    planningPresenter.mountPlanning({
      prefectureId: Number(selectedPrefecture),
      subgroupsWithGroup: planningItems,
    });
  };

  const groupsAlreadySelected = increaseGroupParams
    ? planning[increaseGroupParams.cashflowLevelKey].transactionTypes[
      increaseGroupParams.transactionTypeLevelKey
    ].groups.map(({ groupId }) => groupId)
    : [];

  const groupsListFiltered = groupsList.filter(
    ({ id }) => !groupsAlreadySelected.includes(id)
  );

  const subgroupsAlreadySelected = increaseSubgroupParams
    ? planning[increaseSubgroupParams.cashflowLevelKey].transactionTypes[
      increaseSubgroupParams.transactionTypeLevelKey
    ].groups[increaseSubgroupParams.groupLevelKey].subgroups?.map(
      ({ subgroupId }) => subgroupId
    ) ?? []
    : [];

  const subgroupsListFiltered = subgroupsList.filter(
    ({ id }) => !subgroupsAlreadySelected.includes(id)
  );

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          breadcrumbItem={t("planning.title")}
          title={t("Prefectures")}
        />

        <Row>
          <Col sm={6}>
            <select
              className="form-control mb-5"
              onChange={handlePrefectureChange}
            >
              <option value="">{t("planning.select")}</option>

              {prefectures.map(({ id, cityName, stateName }) => (
                <option value={id} key={id}>
                  {cityName} - {stateName}
                </option>
              ))}
            </select>
          </Col>

          <Col sm={6}>
            <div className="d-sm-flex align-items-center justify-content-end">
              <button
                type="button"
                className="btn btn-primary mb-3 ms-1"
                onClick={() => setIsCopyingPlanning(true)}
              >
                {t("Copy chart of accounts")}
              </button>
            </div>
          </Col>

          {isLoading ? (
            <Col sm={12}>
              <Loading />
            </Col>
          ) : (
            <Col sm={12}>

              <Card>
                <CardBody>
                  <Nav className="nav-tabs-custom card-header-tabs">
                    {planning.map(({ cashflow, cashflowName }) => (
                      <NavItem key={cashflow}>
                        <NavLink
                          to="#"
                          className={`px-3 nav-link ${activeCashflowId === cashflow ? "active" : ""
                            }`}
                          onClick={() => setActiveCashflowId(cashflow)}
                        >
                          {cashflowName}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </CardBody>
              </Card>

              {Boolean(planning.length) && (
                <button
                  type="button"
                  className="btn btn-primary mb-3 ms-1"
                  onClick={savePlanning}
                >
                  {t("planning.save")}
                </button>
              )}

              {Boolean(planning.length) &&
                planning
                  .map((cashflowLevel, cashflowLevelKey) => cashflowLevel.cashflow === activeCashflowId ? (
                    <Table key={Unique.key()} bordered className="mt-3">
                      <tbody>
                        <tr style={{ backgroundColor: "#f3f3f3" }}>
                          <th>{cashflowLevel.cashflowName}</th>
                          <th style={{ width: "250px" }} className="text-center">
                            {t("Actions")}
                          </th>
                        </tr>

                        {cashflowLevel.transactionTypes.map(
                          (transactionTypeLevel, transactionTypeLevelKey) => (
                            <>
                              <tr key={Unique.key()}>
                                <th style={{ paddingLeft: "20px" }}>
                                  {transactionTypeLevel.transactionTypeName}
                                </th>

                                <td className="text-center">
                                  <TableActionButton
                                    label={t("planning.increase.group")}
                                    onClick={() =>
                                      setIncreaseGroupParams({
                                        cashflowLevelKey,
                                        transactionTypeLevelKey,
                                      })
                                    }
                                  />
                                </td>
                              </tr>

                              {transactionTypeLevel.groups.map(
                                (groupLevel, groupLevelKey) => (
                                  <>
                                    <tr
                                      key={Unique.key()}
                                      style={{ backgroundColor: "#f3f3f3" }}
                                    >
                                      <td style={{ paddingLeft: "40px" }}>
                                        {groupLevel.groupName}
                                      </td>

                                      <td className="text-center">
                                        <TableActionButton
                                          label={t("planning.increase.subgroup")}
                                          onClick={() =>
                                            setIncreaseSubgroupParams({
                                              cashflowLevelKey,
                                              groupLevelKey,
                                              transactionTypeLevelKey,
                                            })
                                          }
                                        />

                                        <TableActionButton
                                          label={t("planning.remove")}
                                          isLastOfList
                                          onClick={() =>
                                            decreaseGroup({
                                              cashflowLevelKey,
                                              transactionTypeLevelKey,
                                              groupId: groupLevel.groupId,
                                            })
                                          }
                                        />
                                      </td>
                                    </tr>

                                    {groupLevel.subgroups?.map(
                                      (subgroupLevel) => (
                                        <tr key={Unique.key()}>
                                          <td style={{ paddingLeft: "60px" }}>
                                            {subgroupLevel.subgroupName}
                                          </td>

                                          <td className="text-center">
                                            <TableActionButton
                                              label={t(
                                                "planning.remove.subgroup"
                                              )}
                                              onClick={() =>
                                                decreaseSubgroup({
                                                  cashflowLevelKey,
                                                  groupLevelKey,
                                                  transactionTypeLevelKey,
                                                  subgroupId:
                                                    subgroupLevel.subgroupId,
                                                })
                                              }
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </>
                                )
                              )}
                            </>
                          )
                        )}
                      </tbody>
                    </Table>
                  ) : null)}
            </Col>
          )}
        </Row>
      </Container>

      <CopyPlanning
        isOpen={isCopyingPlanning}
        onDismiss={() => setIsCopyingPlanning(false)}
      />

      <IncreaseGroup
        onDismiss={() => setIncreaseGroupParams(null)}
        open={increaseGroupParams}
        groupList={groupsListFiltered}
        onSubmit={increaseGroup}
      />

      <IncreaseSubgroup
        onDismiss={() => setIncreaseSubgroupParams(null)}
        open={increaseSubgroupParams}
        subgroupList={subgroupsListFiltered}
        onSubmit={increaseSubgroup}
      />
    </div>
  );
};

export default withTranslation()(PlanningView);
